import React, { useRef } from 'react';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import RequestForm from './components/RequestForm';

function App() {
  const formRef = useRef(null);

  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="App">
      <Header />
      <div className="main-content">
        <div className="main-background"></div>
        <div className="main-content-text">
          <h1 className="main-title">Komaru.Mail</h1>
          <p className="main-description">
            Добро пожаловать в Komaru.Mail! Это сервис для отправки и отслеживания посылок на сервере 
            Тайгерии. Здесь вы можете легко отправить посылку или получить ее.
          </p>
          <button className="scroll-button" onClick={scrollToForm}>
            Отправьте свою посылку!
          </button>
        </div>
      </div>
      <div ref={formRef}>
        <RequestForm />
      </div>
      <Footer />
    </div>
  );
}

export default App;
