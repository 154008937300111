import React, { useState } from 'react';
import './RequestForm.css';

const RequestForm = () => {
  const [discord, setDiscord] = useState('');
  const [recipient, setRecipient] = useState('');
  const [address, setAddress] = useState('');
  const [weight, setWeight] = useState('');
  const [message, setMessage] = useState('');
  const [trackingNumber, setTrackingNumber] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [postOffice, setPostOffice] = useState('');
  const [cell, setCell] = useState({ color: '', number: '' });

  const generateTrackingNumber = () => {
    return Math.floor(Math.random() * 900) + 100;
  };

  const chooseCellColorAndNumber = () => {
    const colors = ['лаймовый', 'синий', 'голубой'];
    const randomColorIndex = Math.floor(Math.random() * colors.length);
    const randomNumber = Math.floor(Math.random() * 3) + 1;
    return {
      color: colors[randomColorIndex],
      number: randomNumber
    };
  };

  const sendMessage = async (trackingNumber) => {
    const webhookUrl = 'https://discord.com/api/webhooks/1252319842141208707/i0XLKP-oD4iO7r_YKExkM9NzKvIL_QLQuex2_VOv3jqJQoy_wH24vOPM1ey2jENGepAb';

    const cell = chooseCellColorAndNumber();

    const payload = {
      content: `трек: ${trackingNumber}\n цвет: ${cell.color}\n номер: ${cell.number}\n дс отправителя: ${discord}\n получатель: ${recipient}\n адрес: ${address}\n вес: ${weight} кг\n сообщение: ${message}\n отделение: ${postOffice}`,
    };

    try {
      const response = await fetch(webhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setCell(cell);
        setTrackingNumber(trackingNumber);
        setIsModalOpen(true);
      } else {
        alert('Ошибка при отправке запроса');
      }
    } catch (error) {
      console.error('Ошибка:', error);
      alert('Ошибка при отправке запроса');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const trackingNumber = generateTrackingNumber();
    sendMessage(trackingNumber);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleTrackOrder = () => {
    closeModal();
    window.location.reload();
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="request-form">
        <h2>Отправить запрос на отправку посылки</h2>
        <div className="form-group">
          <label>Ваш Discord:</label>
          <input
            type="text"
            value={discord}
            onChange={(e) => setDiscord(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Получатель (никнейм):</label>
          <input
            type="text"
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Адрес:</label>
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Вес (кг):</label>
          <input
            type="number"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Сообщение:</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
        <div className="form-group">
          <label>Почтовое отделение:</label>
          <select
            value={postOffice}
            onChange={(e) => setPostOffice(e.target.value)}
            required
          >
            <option value="">Выберите почтовое отделение</option>
            <option value="Отделение КОМАРСКОЕ">Комарская почта (ул. Солевая №2)</option>
            <option value="Отделение ТАЙГЕРИЯ">Почта в Нижегородске (Временно ЗАКРЫТО)</option>
            <option value="Отделение КУЗИНСК">Постомат в Кузинске (ул. Кузи №1)</option>
            <option value="Отделение ТОКИО">Почта в Токио (ул. Камарава №2)</option>
          </select>
        </div>
        <button type="submit">Отправить запрос</button>
      </form>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Ваш запрос отправлен!</h2>
            <p>Ваш трек-номер: {trackingNumber}</p>
            <p>Цвет ячейки: {cell.color}, номер ячейки: {cell.number}</p>
            <p>Пожалуйста, отнесите посылку в выбранное почтовое отделение для отправки.</p>
            <p>Информацию о трек-номере можно узнать у бота Komaru MAIL#8238 в Discord.</p>
            <button onClick={handleTrackOrder}>Закрыть</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RequestForm;
